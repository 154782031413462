<template>
  <div class="edit-event">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <trail
          :steps="steps"
          :canSubmit="canSubmit"
          @change="next"
          :isVirtual="checkIsVirtual"
          :isGuestList="checkIsGuestList"
        />
        <div class="page">
          <Card v-if="loaded">
            <transition mode="out-in" name="fade">
              <router-view
                is-edit-event
                :info="values"
                :errors="errors"
                @data="data"
              />
            </transition>
            <div class="progress-counter">
              <p>Step {{ currentStep.number }} of {{ steps.length }}</p>
            </div>
            <div class="button-wrapper">
              <FormulateInput
                v-if="currentStep.number > 1"
                type="button"
                name="← Back"
                @click="back"
              />

              <FormulateInput
                v-if="currentStep.number >= steps.length"
                type="button"
                name="Submit"
                :disabled="errors.submit"
                @click="send"
              />
              <FormulateInput
                v-else
                type="button"
                name="Next →"
                @click="next"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from "dateformat";
import Trail from "../components/cec/Trail.vue";
import dayjs from "dayjs";
import { whiteLabelRedirection } from "@/helpers/whiteLabel";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import UTC from "dayjs/plugin/utc";
import Timezones from "dayjs/plugin/timezone";

dayjs.extend(AdvancedFormat);
dayjs.extend(UTC);
dayjs.extend(Timezones);

export default {
  name: "edit-event",
  components: {
    Trail
  },
  head() {
    return {
      title: "Edit Event"
    };
  },
  data() {
    return {
      userId: null,
      users: [],
      user: null,
      loaded: false,
      steps: [
        {
          number: 1,
          name: "Config",
          link: "config",
          state: "done"
        },
        {
          number: 2,
          name: "Date & Times",
          link: "dates",
          state: "done"
        },
        {
          number: 3,
          name: "Venue",
          link: "venue",
          state: "done"
        },
        {
          number: 4,
          name: "Images",
          link: "images",
          state: "done"
        },
        {
          number: 5,
          name: "Details",
          link: "details",
          state: "done"
        },
        {
          number: 6,
          name: "Tickets",
          link: "tiers",
          state: "done"
        },
        {
          number: 7,
          name: "Products",
          link: "products",
          state: "done"
        },
        {
          number: 8,
          name: "Planner Terms",
          link: "terms",
          state: "done"
        },
        {
          number: 9,
          name: "Submit",
          link: "submit",
          state: "done"
        }
      ],
      values: {
        config: {
          tags: []
        },
        dates: {
          dates: []
        },
        venue: {
          coordinates: {}
        },
        images: {},
        details: {},
        tiers: {
          ticketTiers: [{ id: 0, dfee: 2.0, ccfee: 2.0 }],
          addedTiers: []
        },
        products: {
          products: []
        },
        terms: {},
        submit: {},

        brand: {}
      },
      // if true, the form will have all the error messages displayed once they navigate to the page again
      errors: {
        config: false,
        dates: false,
        venue: false,
        images: false,
        details: false,
        tiers: false,
        products: false,
        terms: false,
        submit: false
      }
    };
  },
  computed: {
    checkIsVirtual() {
      return this.values.config.eventType === "VIRTUAL";
    },
    checkIsGuestList() {
      return this.values.config.ticketsOrGuestList === "guestList";
    },
    currentRoute() {
      return this.$route.path.split("/").pop();
    },
    currentStep() {
      return this.steps.find(s => s.link === this.currentRoute);
    },
    canSubmit() {
      return this.steps.slice(0, -1).every(s => s.state === "done");
    }
  },
  watch: {
    "values.config.ticketsOrGuestList": {
      immediate: true,
      handler(newValue, oldValue) {
        if (this.values.config.ticketsOrGuestList === "guestList") {
          const newSteps = this.steps.filter(step => step.name !== "Tickets");
          this.steps = newSteps;
        } else {
          this.steps = [
            {
              number: 1,
              name: "Config",
              link: "config",
              state: "done"
            },
            {
              number: 2,
              name: "Date & Times",
              link: "dates",
              state: "done"
            },
            {
              number: 3,
              name: "Venue",
              link: "venue",
              state: "done"
            },
            {
              number: 4,
              name: "Images",
              link: "images",
              state: "done"
            },
            {
              number: 5,
              name: "Details",
              link: "details",
              state: "done"
            },
            {
              number: 6,
              name: "Tickets",
              link: "tiers",
              state: "done"
            },
            {
              number: 7,
              name: "Products",
              link: "products",
              state: "done"
            },
            {
              number: 8,
              name: "Planner Terms",
              link: "terms",
              state: "done"
            },
            {
              number: 9,
              name: "Submit",
              link: "submit",
              state: "done"
            }
          ];
        }
      }
    }
  },
  methods: {
    next(next) {
      const index = this.steps.indexOf(this.currentStep);
      let nextStep =
        typeof next === "string"
          ? this.steps.find(s => s.link === next)
          : this.steps[index + 1];

      //skip steps if user choose virtual event
      if (
        nextStep.name === "Venue" &&
        this.values.config.eventType === "VIRTUAL"
      ) {
        nextStep = this.steps[index + 2];
      }

      if (
        nextStep.name === "Tickets" &&
        this.values.config.ticketsOrGuestList === "guestList"
      ) {
        nextStep = this.steps[index + 2];
      }

      if (!nextStep || nextStep.state === "disabled") {
        return;
      }

      if (nextStep.name !== "submit" || this.canSubmit) {
        if (this.currentRoute !== nextStep.name) {
          this.$router.push(
            `/edit-event/${this.$route.params.url}/${nextStep.link}`
          );
        }
      }
    },
    back() {
      const index = this.steps.indexOf(this.currentStep);
      if (index > -1) {
        let previousStep = this.steps[index - 1];
        //skip steps if user choose virtual event
        if (
          previousStep.name === "Venue" &&
          this.values.config.eventType === "VIRTUAL"
        ) {
          previousStep = this.steps[index - 2];
        }

        if (
          previousStep.name === "Tickets" &&
          this.values.config.ticketsOrGuestList === "guestList"
        ) {
          previousStep = this.steps[index - 2];
        }

        if (previousStep) {
          this.$router.push(
            `/edit-event/${this.$route.params.url}/${previousStep.link}`
          );
        }
      }
    },
    data(key, value, error) {
      const validated = typeof error === "boolean";

      this.values[key] = value;

      if (validated) {
        this.errors[key] = error;

        const step = this.steps.find(s => s.link === key);
        if (step) {
          step.state = error ? "error" : "done";
        }

        if (key === "config" && !error) {
          this.steps
            .filter(
              s =>
                ((s.number > 1 && s.number < 6) || s.number === 8) &&
                s.state === "disabled"
            )
            .forEach(s => {
              s.state = "unchanged";
            });
        }

        if (this.values.config.eventType === "VIRTUAL") {
          this.steps
            .filter(x => x.number === 3)
            .forEach(y => {
              y.state = "done";
            });
        }

        if (this.values.config.ticketsOrGuestList === "guestList") {
          this.steps
            .filter(x => x.number === 6)
            .forEach(y => {
              y.state = "done";
            });
        }

        if (key === "dates" && !error) {
          this.validateTierDates();

          const datesStep = this.steps.find(s => s.link === "dates");

          if (datesStep && datesStep.state === "done") {
            const tiersStep = this.steps.find(s => s.link === "tiers");
            const productsStep = this.steps.find(s => s.link === "products");
            if (
              tiersStep &&
              tiersStep.state !== "done" &&
              tiersStep.state !== "error"
            ) {
              tiersStep.state = "unchanged";
            }

            if (
              productsStep &&
              productsStep.state !== "done" &&
              productsStep.state !== "error"
            ) {
              productsStep.state = "unchanged";
            }
          }
        }
      }

      if (this.canSubmit) {
        this.steps.find(s => s.link === "submit").state = this.errors.submit
          ? "error"
          : "unchanged";
      }
    },
    validateTierDates() {
      if (this.values.tiers.addedTiers.length === 0) {
        return;
      }

      const start = dayjs(this.values.dates.dates[0].startDate);
      const end = dayjs(this.values.dates.dates[0].endDate);

      for (const tier of this.values.tiers.addedTiers) {
        if (dayjs(tier.venueAccessStart).isBefore(start)) {
          tier.venueAccessStart = start.toISOString();
        }

        if (dayjs(tier.venueAccessEnd).isAfter(end)) {
          tier.venueAccessEnd = end.toISOString();
        }

        // for (const saleDate of tier.saleDates) {
        //   if (dayjs(saleDate.startDate).isBefore(start)) {
        //     saleDate.startDate = start.toISOString();
        //   }

        //   if (dayjs(saleDate.endDate).isAfter(end)) {
        //     saleDate.endDate = end.toISOString();
        //   }
        // }
      }
    },
    removeSpans(val) {
      let textToSend = new String(val);
      let regex = /(<span><\/span>)+/;
      let returnText = textToSend.replace(regex, "");
      return returnText;
    },
    isEventVenueVirtual() {
      if (this.values.config.eventType === "VIRTUAL") {
        this.values.venue = {
          secretVenue: false,
          image: "",
          venueName: "",
          venueAddress: "",
          cityName: "",
          regionName: "",
          regionCode: "",
          zipCode: "",
          contactNumber: "",
          closestCityName: "",
          coordinates: {}
        };
      }
    },
    async send() {
      this.$store.state.creating = true;
      let lastIndexDate = this.values.dates.dates.length - 1;
      const start = this.values.dates.dates[0].startDate;
      const end = this.values.dates.dates[lastIndexDate].endDate;

      this.isEventVenueVirtual();

      const finalTiers = [];
      const tiers = this.values.tiers.addedTiers;

      if (this.values.config.funnel_id.length > 0) {
        this.values.config.funnel_id = this.values.config.funnel_id.map(f =>
          parseInt(f.id)
        );
      }
      for (const tax of this.values.config.taxes) {
        tax.percentage = Number(tax.percentage);
      }

      for (let x = 0; x < tiers.length; x++) {
        let obj = {};

        let imageName =
          tiers[x].image && tiers[x].image.imageName
            ? tiers[x].image.imageName
            : "";

        obj.id = tiers[x].id;
        obj.name = tiers[x].name;
        obj.description = tiers[x].description;
        obj.currencyType = tiers[x].currencyType;
        obj.price = tiers[x].price;
        obj.image = { imageName: imageName };
        obj.ccfee = tiers[x].ccfee;
        obj.dfee = tiers[x].dfee;
        obj.addfee = tiers[x].addfee;
        obj.absorb = tiers[x].absorb;
        obj.type = tiers[x].type;
        obj.ticketType = tiers[x].ticketType;
        obj.tixStartNum = parseInt(tiers[x].tixStartNum);
        obj.totalTicketQuantity = tiers[x].totalTicketQuantity;
        obj.venueAccess = {
          start: tiers[x].venueAccessStart,
          end: tiers[x].venueAccessEnd
        };
        obj.categoryKey = tiers[x].categoryKey;
        obj.venueAccessDates = tiers[x].venueAccessDates;
        obj.saleDates = tiers[x].saleDates.map(element => {
          return {
            id: element.id,
            sale_start: element.startDate,
            sale_end: element.endDate,
            qty: element.qty,
            soldout: element.soldout,
            purchase_limit: element.purchase_limit,
            show_check_out_add_on: element.show_check_out_add_on,
            do_not_show_in_event: element.do_not_show_in_event,
            min_purchase: parseInt(element.min_purchase),
            max_purchase: parseInt(element.max_purchase)
          };
        });

        if (tiers[x].password && tiers[x].password.length > 0) {
          obj.password = tiers[x].password;
        } else {
          obj.password = false;
        }
        if (tiers[x].tag_id) {
          obj.tag_id = tiers[x].tag_id;
        }
        if (tiers[x].product_id) {
          obj.product_id = tiers[x].product_id;
        }
        if (tiers[x].cf_product_id) {
          obj.cf_product_id = tiers[x].cf_product_id;
        }
        if (tiers[x].restrictEmails && tiers[x].restrictEmailsArray) {
          obj.restrictEmailsArray = tiers[x].restrictEmailsArray;
          obj.restrictEmails = tiers[x].restrictEmails;
        }
        if (tiers[x].userEmails) {
          obj.userEmails = tiers[x].userEmails;
        }
        if (tiers[x].tag) {
          obj.tag = tiers[x].tag;
        }

        if (tiers[x].attendee_tag_id) {
          obj.attendee_tag_id = tiers[x].attendee_tag_id;
        }

        if (tiers[x].attendee_removed_tag_id) {
          obj.attendee_removed_tag_id = tiers[x].attendee_removed_tag_id;
        }
        if (tiers[x].keap_quantity_custom_field_id) {
          obj.keap_quantity_custom_field_id =
            tiers[x].keap_quantity_custom_field_id;
        }
        finalTiers.push(obj);
      }

      let finalItineraries = [];
      let finalFields = [];
      let finalExcludedDates = [];

      let finalDescription = this.removeSpans(this.values.details.description);

      const products = this.values.products.products.map(m => {
        return { ...m, image: undefined };
      });

      let objToSend = {
        name: this.values.config.name,
        ticketsOrGuestList: this.values.config.ticketsOrGuestList,
        buyerOrPlannerPaysFees: this.values.config.buyerOrPlannerPaysFees,
        collectAttendeeInfo: this.values.config.collectAttendeeInfo,
        pixelId: this.values.config.pixelID,
        obv_link: this.values.config.obv_link,
        sw_link: this.values.config.sw_link,
        url: this.$route.params.url,
        tagline: this.values.config.tagline,
        venue: this.values.venue.venueName,
        start: start, // TODO
        end: end, // TODO
        enable_pre_sale: this.values.dates.enable_pre_sale,
        hide_event_dates: this.values.dates.hide_event_dates,
        replay: this.values.dates.replay,
        eventType: this.values.config.eventType,
        timezone: this.values.config.timezone,
        taxes: this.values.config.taxes,
        seatsEventKey: this.values.config.seatsEventKey,
        frequency: this.values.dates.frequency,
        dates: this.values.dates.dates,
        dateSettings: {
          days: this.values.dates.days,
          weeks: this.values.dates.weeks,
          startDate: dayjs(+this.values.dates.startDate),
          startTime: dayjs(+this.values.dates.startTime),
          endDate: dayjs(+this.values.dates.endDate),
          endTime: dayjs(+this.values.dates.endTime),
          endOffset: this.values.dates.endOffset,
          monthlyType: this.values.dates.monthlyType,
          monthlyDay: this.values.dates.monthlyDay
        },
        city: this.values.venue.cityName,
        closestCity: this.values.venue.closestCityName,
        contactNumber: this.values.venue.contactNumber,
        region_name: this.values.venue.regionName,
        region_code: this.values.venue.regionCode,
        country_name: this.values.config.country,
        currency: this.values.config.currency,
        languages: this.values.config.languages,
        email: this.$store.state.user.email, // TODO
        active: true, // TODO
        socialDiscount: this.values.tiers.socialDiscount,
        guestlistOnly: this.values.tiers.isGuestListOnly,
        tiers: finalTiers,
        archived: false,
        featured: false,
        excludedDates: finalExcludedDates, // TODO
        venueAddress: this.values.venue.venueAddress,
        description: finalDescription,
        zipCode: this.values.venue.zipCode,
        hide: this.values.submit.hide,
        hide_in_brand: this.values.submit.hide_in_brand,
        proofread: this.values.submit.proofread,
        hideFees: this.values.submit.hideFees,
        agree: this.values.submit.agree,
        itineraries: finalItineraries, // TODO
        videoLink: this.values.details.videoLink,
        website: this.values.details.website,
        sponsor: this.values.details.sponsor,
        sponsorLink: this.values.details.sponsorLink,
        addedFields: finalFields, // TODO
        privateVenue: this.values.venue.secretVenue,
        primaryCategory: this.values.config.primaryCategory,
        secondaryCategory: this.values.config.secondaryCategory,
        unchangedSmallImages: this.values.images.images.small.reduce(
          (a, s, i) => {
            if (s && !s.startsWith("data")) {
              a.push(i);
            }

            return a;
          },
          []
        ),
        products,
        tags: this.values.config.tags,
        refundPolicy: this.values.terms.refundPolicy,
        privacyPolicy: this.values.terms.privacyPolicy,
        termsOfUse: this.values.terms.termsOfUse,
        coordinates: this.values.venue.coordinates,

        // CF Values
        cf_event_url: this.values.config.cf_event_url,
        funnel_id: this.values.config.funnel_id,

        // Keap Values
        keap_language_custom_field_id: this.values.config
          .keap_language_custom_field_id
          ? parseInt(this.values.config.keap_language_custom_field_id)
          : null,
        keap_holder_tag_id: this.values.config.keap_holder_tag_id
          ? parseInt(this.values.config.keap_holder_tag_id)
          : null,

        type: this.values.tiers.type,
        enable_upgrade_ticket: this.values.terms.enable_upgrade_ticket,
        enable_exchange_ticket: this.values.terms.enable_exchange_ticket,
        enable_refund_ticket: this.values.terms.enable_refund_ticket,
        enable_transfer_ticket: this.values.terms.enable_transfer_ticket,
        enable_refund_ticket_insurance: this.values.terms
          .enable_refund_ticket_insurance
      };

      // send as FormData to properly handle image uploads

      const data = new FormData();

      data.append("data", JSON.stringify(objToSend));

      data.append("hero", this.values.images.images.heroFile);

      for (let i = 0; i < this.values.images.images.smallFiles.length; i++) {
        data.append(`small-${i}`, this.values.images.images.smallFiles[i]);
      }

      data.append("venueImage", this.values.venue.image.imageFile);

      for (let i = 0; i < this.values.tiers.addedTiers.length; i++) {
        if (this.values.tiers.addedTiers[i].image) {
          data.append(
            `tier-${i}`,
            this.values.tiers.addedTiers[i].image.imageFile
          );
        }
      }

      for (let i = 0; i < this.values.products.products.length; i++) {
        if (this.values.products.products[i].isNew) {
          data.append(
            `products-${i}`,
            this.values.products.products[i].image.imageFile
          );
        }
      }

      this.$axios
        .post("/events/edit", data)
        .then(async response => {
          if (response.status == 200) {
            setTimeout(async () => {
              const domainUrl = await whiteLabelRedirection(
                process.env.VUE_APP_ENVIRONMENT,
                this.userId,
                this.$route.params.url
              );
              // window.open(domainUrl, "_blank", "noreferrer");
              this.$router.push(`/dashboard`);
              this.$store.state.creating = false;
            }, 1000);
          }
        })
        .catch(error => {
          this.$store.state.creating = false;
          console.log(error);
        });
    }
  },
  async created() {
    this.$store.commit("setTitle", "Edit Event");
    this.$store.commit(
      "setHelpVideoSrc",
      "https://youtube.com/embed/nWZPCegWPAc"
    );
    this.$loader.start();
    this.$axios
      .get(`/events/edit/${this.$route.params.url}`)
      .then(response => {
        this.oldEvent = response.data;
        //resetting the old event data that will not be clonned

        const data = response.data;
        this.userId = data.user;
        this.values.config = {
          country: data.country_name,
          name: data.name,
          tagline: data.tagline,
          pixelID: data.pixelId,
          obv_link: data.obv_link,
          sw_link: data.sw_link,
          primaryCategory: data.primaryCategory,
          secondaryCategory: data.secondaryCategory,
          tags: data.tags || [],
          timezone: data.timezone,
          taxes: data.taxes || [],
          funnel_id: data.funnel_id
            ? data.funnel_id.map(f => {
                if (f) {
                  return { id: f.toString() };
                }
              })
            : [],
          cf_event_url: data.cf_event_url,
          // Keap Values
          keap_language_custom_field_id:
            data.keap_language_custom_field_id || "",
          keap_holder_tag_id: data.keap_holder_tag_id || "",

          ticketsOrGuestList: data.ticketsOrGuestList,
          buyerOrPlannerPaysFees: data.buyerOrPlannerPaysFees,
          collectAttendeeInfo: data.collectAttendeeInfo,
          eventType: data.eventType ? data.eventType : "HYBRID",
          seatsEventKey: data.seats_event_key || "",
          currency: data.currency,
          languages: data.languages
        };

        // TODO: set to SINGLE_DATE by default
        if (!data.frequency) {
          data.frequency = "SINGLE_DATE";
        }

        if (data.frequency === "SINGLE_DATE") {
          data.dates = [
            {
              start: dayjs(+data.start.$date.$numberLong).unix() * 1000,
              end: dayjs(+data.end.$date.$numberLong).unix() * 1000
            }
          ];
        }

        if (data.dateSettings && !Array.isArray(data.dateSettings)) {
          const settings = data.dateSettings;

          settings.startDate = dayjs(
            settings.startDate.$date
              ? +settings.startDate.$date.$numberLong
              : settings.startDate
          ).toISOString();
          settings.startTime = dayjs(
            settings.startTime.$date
              ? +settings.startTime.$date.$numberLong
              : ""
          ).toISOString();
          settings.endDate = dayjs(
            settings.endDate.$date ? +settings.endDate.$date.$numberLong : ""
          ).toISOString();
          settings.endTime = dayjs(
            settings.endTime.$date ? +settings.endTime.$date.$numberLong : ""
          ).toISOString();
        }

        this.values.dates = {
          frequency: data.frequency,
          dates: data.dates.map(d => ({
            ...d,
            startDate:
              data.frequency === "SINGLE_DATE"
                ? dayjs(+d.start).toISOString()
                : dayjs(d.start)
                    .tz(this.values.config.timezone)
                    .toISOString(),
            endDate:
              data.frequency === "SINGLE_DATE"
                ? dayjs(+d.end).toISOString()
                : dayjs(d.end)
                    .tz(this.values.config.timezone)
                    .toISOString()
          })),
          enable_pre_sale: data.enable_pre_sale,
          hide_event_dates: data.hide_event_dates,
          replay: data.replay,
          ...(data.dateSettings || {})
        };

        this.values.venue = {
          venueName: data.venue,
          image: {
            image: process.env.VUE_APP_IMAGE_URL + data.venueImage.image,
            imageName: data.venueImage.imageName
          },
          cityName: data.city,
          closestCityName: data.closestCity,
          contactNumber: data.contactNumber,
          regionName: data.region_name,
          regionCode: data.region_code,
          venueAddress: data.venueAddress,
          zipCode: data.zipCode,
          secretVenue: data.privateVenue,
          coordinates: data.coordinates || {}
        };

        this.values.images = {
          // imageDetails:
          images: {
            hero: process.env.VUE_APP_IMAGE_URL + data.images.hero,
            heroFile: null,
            small: data.images.small.map(s =>
              s ? process.env.VUE_APP_IMAGE_URL + s : null
            ),
            smallFiles: []
          }
        };

        this.values.details = {
          description: data.description,
          videoLink: data.videoLink,
          website: data.website,
          sponsor: data.sponsor,
          sponsorLink: data.sponsorLink,
          itineraries: []
        };

        this.values.tiers = {
          ticketTiers: data.tiers.map(t => ({
            id: t.id,
            ccfee: t.ccfee,
            dfee: t.dfee
          })),
          addedTiers: data.tiers.map(t => ({
            ...t,
            ticketType: t.ticketType ? t.ticketType : "IN_PERSON",

            image: {
              image:
                t.image && t.image.image
                  ? process.env.VUE_APP_IMAGE_URL + t.image.image
                  : "",
              imageName: t.image && t.image.imageName ? t.image.imageName : ""
            },

            saleDates: t.saleDates.map(d => ({
              ...d,
              startDate: dayjs(+d.sale_start.$date.$numberLong).toISOString(),
              endDate: dayjs(+d.sale_end.$date.$numberLong).toISOString()
            })),
            venueAccessDates:
              t.type !== "General Tier"
                ? t.venueAccessDates.map(d => {
                    // debugger;
                    const date = {
                      ...d,
                      start: dayjs(+d.start.$date.$numberLong).toISOString(),
                      end: dayjs(+d.end.$date.$numberLong).toISOString(),
                      intervalAmount: d.interval_amount
                    };

                    if (d.times && Array.isArray(d.times)) {
                      for (const time of date.times) {
                        time.start = dayjs(+time.start).toISOString();
                        time.end = dayjs(+time.end).toISOString();
                      }
                    }

                    return date;
                  })
                : [],
            venueAccessStart:
              t.type === "General Tier"
                ? dayjs(+t.venueAccess.start.$date.$numberLong).toISOString()
                : "",
            venueAccessEnd:
              t.type === "General Tier"
                ? dayjs(+t.venueAccess.end.$date.$numberLong).toISOString()
                : ""
          })),
          type: data.type,
          socialDiscount: data.socialDiscount,
          isGuestListOnly: data.guestlistOnly
        };

        this.values.products = {
          products: data.products.map(m => ({
            ...m,
            image: {
              image: process.env.VUE_APP_IMAGE_URL + m.image_url,
              imageName: m.image_name
            },
            variants: m.variants.map((v, i) => ({
              ...v,
              key: Date.now() + `${i}`,
              isNew: false
            })),
            isPrevious: true
          }))
        };

        this.values.terms = {
          refundPolicy: data.refundPolicy,
          privacyPolicy: data.privacyPolicy,
          termsOfUse: data.termsOfUse,
          enable_upgrade_ticket: data.enable_upgrade_ticket,
          enable_exchange_ticket: data.enable_exchange_ticket,
          enable_refund_ticket: data.enable_refund_ticket,
          enable_transfer_ticket: data.enable_transfer_ticket,
          enable_refund_ticket_insurance: data.enable_refund_ticket_insurance
        };

        this.values.submit = {
          hide: data.hide,
          hide_in_brand: data.hide_in_brand,
          proofread: data.proofread,
          hideFees: data.hideFees,
          agree: data.agree
        };

        if (data.cloned_from && !data.clone_complete) {
          this.steps.forEach(step => {
            step.state = "unchanged";
          });
        }
      })
      .then(response => {
        this.values.tiers.addedTiers.forEach(ele => {
          // the component that handles multiple dates wants it in the original format,
          // but it seems like otherwise it wants it formatted. im just putting this
          // comment here because ive given up on any hope i had that keegan wasnt high
          // as a kite when writing this shit
          if (ele.saleDates.length <= 1) {
            ele.saleDates.forEach(saleDate => {
              if (saleDate.sale_start !== null) {
                saleDate.sale_start = dateFormat(
                  new Date(+saleDate.sale_start.$date.$numberLong),
                  "yyyy-mm-dd hh:MM TT"
                );
              }

              if (saleDate.sale_end !== null) {
                saleDate.sale_end = dateFormat(
                  new Date(+saleDate.sale_end.$date.$numberLong),
                  "yyyy-mm-dd hh:MM TT"
                );
              }
            });
          }
        });
        // let excludedDatesToUse = this.excludedDates.map((ele) => {
        //   let dayObj = dateFormat(
        //     new Date(+ele.excludedDate.$date.$numberLong),
        //     "yyyy-mmmm-d"
        //   );
        //   let dayToInject = {
        //     year: dayObj[1],
        //     month: dayObj[0],
        //     day: dayObj[2],
        //   };
        //   return dayToInject;
        // });
        // this.dateInfo.excludedDates.splice(
        //   0,
        //   this.dateInfo.excludedDates.length,
        //   ...excludedDatesToUse
        // );
        // this.infoInfo.itineraries.forEach((itinerary, index) => {
        //   itinerary.id = index;
        // });

        this.isTiersReady = true;
        this.datesAreReady = true;
        // this.updateItineraryFlag();

        this.$loader.stop();
      })
      .then(response => {
        this.loaded = true;
      });

    this.$axios
      .post("/company/get-info", {
        type: "companyInfo",
        url: this.$store.state.activeBrand.subdomain
      })
      .then(({ data }) => {
        this.values.brand = data;
      });
  },
  mounted() {
    // setTimeout(() => {
    // }, 2000);
    // this.$bus.$emit("alert", {
    //   title: "Please Note",
    //   msg: "Once you have made your changes, select 'Submit' on the left panel of your screen and then complete your changes by saving your event.",
    //   confirm: "Okay",
    // });
    this.$axios
      .post("/company/validate-coordinator", {
        email: this.userEmail
      })
      .then(response => {
        let user;
        if (response.status == 200) {
          user = response.data;
          //gotta make sure redirect modal works
          if (!user.coordinator) {
            this.$parent.redirectModalMsg =
              "Please complete all your brand settings to create events";
            this.$parent.redirectModalLink = "brand-settings";
            this.$parent.redirectModal = true;
          }
        }
      });
  }
};
</script>

<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#Content {
  // transition: height 0.5s;

  .content-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-top: 80px;

    .page {
      display: flex;
      width: 100%;
      flex-direction: column;

      &::v-deep {
        .progress-counter {
          margin: 100px auto 0.5em;
          max-width: 425px;

          p {
            margin: 0;
            font-size: 1.1em !important;
            font-weight: 500;
            text-align: center;
            word-spacing: 1.5px;
            text-transform: uppercase;
            color: #999999;
          }
        }

        .card h2,
        .card h3 {
          margin-top: 0;
        }

        .card .divider {
          margin: 24px 0;
        }
      }

      .button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        gap: 16px;

        .formulate-input {
          display: flex;
          width: unset;
        }
      }
    }
  }

  @media screen and (max-width: 1015px) {
    .content-inner {
      flex-wrap: wrap;
    }
  }
}
</style>
